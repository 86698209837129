import React from "react"
import { css } from "@emotion/core"
import Layout from "../../components/Layout"
import ProjectHero from "../../components/heros/ProjectHero"
import DoubleColumn from "../../components/projects/DoubleColumn"
import Download from "../../components/projects/Download"
import Logo from "../../components/projects/Logo"
import NextProject from "../../components/projects/NextProject"
import Banner from "../../components/projects/Banner"
import BannerDoubleColumn from "../../components/projects/BannerDoubleColumn"

import HeroImage from "../../images/projects/erste/hero.jpg"
import ScreenshotImage from "../../images/projects/erste/screenshot.png"
import BannerImage from "../../images/projects/erste/banner.jpg"
import ErsteImage from "../../images/projects/erste/erste_logo.png"
import GroupImage from "../../images/projects/erste/group.png"
import LogoImage from "../../images/projects/erste/logo.png"

import NextImage from "../../images/projects/info/pulzus.jpg"

const Next = () => (
  <NextProject
    image={NextImage}
    title="Pulzus"
    description="Change the world with your opinion. We are open to listening to them."
    to="pulzus"
  />
)

const Hero = () => (
  <ProjectHero
    title="Official application of Erste Liga"
    subtitle="We developed the official application of the Erste Liga, which keeps us up-to-date with the status of the ice hockey tournament."
  />
)

const ErsteProject = () => (
  <Layout withHero heroContent={Hero} heroImage={HeroImage} infoContent={Next}>
    <div
      css={theme => css`
        text-align: center;
        min-height: calc(
          100vh - ${theme.constants.infoFullHeight / 2}px -
            ${theme.constants.footerHeight}px -
            ${theme.constants.headerHeight}px
        );
        justify-content: center;
        display: flex;
        flex-direction: column;

        @media (max-width: 1000px) {
          position: relative;
          top: -100px;
        }

        @media (max-width: 650px) {
          position: relative;
          top: -130px;
        }
      `}
    >
      <div
        css={theme => css`
          margin: ${theme.constants.pageMargin};

          @media (max-width: 1200px) {
            margin: ${theme.constants.mediumpageMargin};
          }

          @media (max-width: 1000px) {
            margin: ${theme.constants.smallpageMargin};
          }
        `}
      >
        <DoubleColumn
          first
          title="Everything about ice hockey"
          description="Anyone can follow the championship matches and see the results real time. All the news and statistics about the league is in your pocket. "
          image={ErsteImage}
          border={200}
          top={0}
          flip
          imageOverride={css`
            @media (max-width: 950px) {
              background-position: 50% 0%;
              background-size: 60%;
            }

            @media (max-width: 750px) {
              height: 60vw;
            }
          `}
          css={css`
            @media (min-width: 1440px) {
              margin-bottom: 100px;
            }
          `}
        />
      </div>
      <Banner image={BannerImage} />
      <div
        css={theme => css`
          margin: ${theme.constants.pageMargin};

          @media (max-width: 1200px) {
            margin: ${theme.constants.mediumpageMargin};
          }

          @media (max-width: 1000px) {
            margin: ${theme.constants.smallpageMargin};
          }
          margin-top: 100px;
        `}
      >
        <div
          css={theme => css`
            font-size: ${theme.constants.smallFontSize}px;
            font-weight: ${theme.constants.extraLight};
            margin-bottom: 100px;
          `}
        >
          <p
            css={theme => css`
              font-size: ${theme.constants.mediumFontSize}px;
              font-weight: ${theme.constants.demi};
              margin-bottom: 30px;
            `}
          >
            Hungarian ice hockey is getting traction and becomes more popular
            through the power of digitalisation.
          </p>
          <p>
            There is a huge ice hockey fan base in Hungary. We wanted to bring
            the community together.
          </p>
        </div>
        <DoubleColumn
          title="Your favourite team"
          description="Keep up with the events of the League and check your favourite team’s progression. You want to know who is the best goalie? Just check the app."
          image={ScreenshotImage}
          flip
          top={100}
          border={100}
          css={css`
            margin: 50px 0;
          `}
          imageOverride={css`
            @media (min-width: 1920px) {
              height: 700px;
              padding: 0;
            }
          `}
        />
      </div>
      <BannerDoubleColumn
        title="All events in one place"
        description="Previous matches, upcoming events - all of them in one place. Follow your team in the app, check them on the Leaderboard."
        image={GroupImage}
        top={50}
        border={100}
        color="#004378"
        css={css`
          background-size: 100% 85%;
          height: 500px;
          background-position-y: 0%;
          padding-top: 30px;

          @media (max-width: 650px) {
            height: 700px;
          }
        `}
        imageOverride={css`
          @media (max-width: 650px) {
            background-position: 50% 100%;
            height: 400px;
          }

          @media (min-width: 1920px) {
            height: 500px;
            padding: 0;
          }
        `}
      />
      <div
        css={theme => css`
          margin: ${theme.constants.pageMargin};

          @media (max-width: 1200px) {
            margin: ${theme.constants.mediumpageMargin};
          }

          @media (max-width: 1000px) {
            margin: ${theme.constants.smallpageMargin};
          }
          text-align: center;
        `}
      >
        <div
          css={theme => css`
            font-size: ${theme.constants.smallFontSize}px;
            font-weight: ${theme.constants.extraLight};
            width: 60%;
            margin: 0 auto;
            margin-top: 50px;

            @media (max-width: 1000px) {
              width: 90%;
            }
          `}
        >
          <p
            css={theme => css`
              font-size: ${theme.constants.mediumFontSize}px;
              font-weight: ${theme.constants.demi};
              margin-bottom: 30px;
            `}
          >
            Fan Zone
          </p>
          <p>
            The hockey fans can also watch the rules at any time, as illustrated
            by spectacular and informative animations. The Fan Zone menu item is
            constantly updated with the best Instagram posts of the league and
            the fans.
          </p>
        </div>
        <div
          css={css`
            margin: 50px auto;
          `}
        >
          <Download
            androidLink="https://play.google.com/store/apps/details?id=io.deverest.ersteliga"
            iosLink="https://apps.apple.com/hu/app/erste-liga/id1434674585"
          />
        </div>
      </div>
      <Logo
        src={LogoImage}
        css={css`
          height: 140px;
          margin: 0 auto;
          margin-bottom: 150px;
          margin-top: 50px;

          @media (max-width: 650px) {
            height: 25vw;
            margin-bottom: 0;
          }
        `}
      />
    </div>
  </Layout>
)

export default ErsteProject
